// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/CalcsCarousel.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/CalcsCarousel.tsx");
  import.meta.hot.lastModified = "1720553700493.8704";
}
// REMIX HMR END

import { Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious } from "@/components/ui/carousel";
import React from "react";
export function LatestCalculationCarousel({
  data
}) {
  _s();
  const [api, setApi] = React.useState();
  const [current, setCurrent] = React.useState(0);
  const [count, setCount] = React.useState(0);
  React.useEffect(() => {
    if (!api) {
      return;
    }
    setCount(api.scrollSnapList().length);
    setCurrent(api.selectedScrollSnap() + 1);
    api.on("select", () => {
      setCurrent(api.selectedScrollSnap() + 1);
    });
  }, [api]);
  return <div className="flex justify-center mt-14 sm:hidden">
      <Carousel setApi={setApi} className="w-full max-w-xs max-[450px]:w-60 ">
        <CarouselContent>
          {data && data.latestCalculation.map((item, index) => <CarouselItem key={index}>
                <div className="flex flex-col">
                  <div className="flex flex-col bg-[#E5FBF6]">
                    <div className="mx-4 py-3 border-solid border-b-2 border-white">
                      <span>Applied for:</span>
                      <span className="float-right font-bold">
                        NOK {item.applied}
                      </span>
                    </div>
                    <div className="mx-4 py-3 border-solid border-b-2 border-white">
                      <span>Number of offers:</span>
                      <span className="float-right font-bold">
                        {item.offers}
                      </span>
                    </div>
                    <div className="mx-4 py-3 border-solid border-b-2 border-white">
                      <span>Lowest interest rate:</span>
                      <span className="float-right font-bold">
                        {item.rate}%
                      </span>
                    </div>
                    <div className="mx-4 py-3 border-solid border-b-2 border-white">
                      <span>Total savings:</span>
                      <span className="float-right font-bold text-emerald-700">
                        NOK {item.save}
                      </span>
                    </div>
                    <div className="mx-4 py-3 ">
                      <span>Best bank:</span>
                      <span className="float-right font-bold">{item.bank}</span>
                    </div>
                  </div>
                  <div style={{
              width: "0px",
              height: "0px",
              borderStyle: "solid",
              borderWidth: "16px 16px 0 0px",
              borderColor: "#E5FBF6 transparent transparent transparent",
              transform: "rotate(0deg)"
            }}></div>
                  <div className="flex items-center py-2">
                    <div className="bg-[url('./images/latest-calc/person-1.svg')] w-12 h-12 bg-contain"></div>
                    <div className="font-bold">{`${item.name}, ${item.age}`}</div>
                  </div>
                </div>
              </CarouselItem>)}
        </CarouselContent>
        <CarouselPrevious />
        <CarouselNext />
      </Carousel>
    </div>;
}
_s(LatestCalculationCarousel, "xFYzdWafWLdwWG2x54690V46Ld8=");
_c = LatestCalculationCarousel;
var _c;
$RefreshReg$(_c, "LatestCalculationCarousel");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;