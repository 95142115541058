// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/ui/carousel.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$(),
  _s2 = $RefreshSig$(),
  _s3 = $RefreshSig$(),
  _s4 = $RefreshSig$(),
  _s5 = $RefreshSig$(),
  _s6 = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/ui/carousel.tsx");
  import.meta.hot.lastModified = "1722534133440.1057";
}
// REMIX HMR END

import * as React from "react";
import useEmblaCarousel from "embla-carousel-react";
import { ArrowLeft, ArrowRight } from "lucide-react";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
const CarouselContext = React.createContext(null);
function useCarousel() {
  _s();
  const context = React.useContext(CarouselContext);
  if (!context) {
    throw new Error("useCarousel must be used within a <Carousel />");
  }
  return context;
}
_s(useCarousel, "b9L3QQ+jgeyIrH0NfHrJ8nn7VMU=");
const Carousel = _s2(React.forwardRef(_c = _s2(({
  orientation = "horizontal",
  opts,
  setApi,
  plugins,
  className,
  children,
  ...props
}, ref) => {
  _s2();
  const [carouselRef, api] = useEmblaCarousel({
    ...opts,
    axis: orientation === "horizontal" ? "x" : "y"
  }, plugins);
  const [canScrollPrev, setCanScrollPrev] = React.useState(false);
  const [canScrollNext, setCanScrollNext] = React.useState(false);
  const onSelect = React.useCallback(api => {
    if (!api) {
      return;
    }
    setCanScrollPrev(api.canScrollPrev());
    setCanScrollNext(api.canScrollNext());
  }, []);
  const scrollPrev = React.useCallback(() => {
    api?.scrollPrev();
  }, [api]);
  const scrollNext = React.useCallback(() => {
    api?.scrollNext();
  }, [api]);
  const handleKeyDown = React.useCallback(event => {
    if (event.key === "ArrowLeft") {
      event.preventDefault();
      scrollPrev();
    } else if (event.key === "ArrowRight") {
      event.preventDefault();
      scrollNext();
    }
  }, [scrollPrev, scrollNext]);
  React.useEffect(() => {
    if (!api || !setApi) {
      return;
    }
    setApi(api);
  }, [api, setApi]);
  React.useEffect(() => {
    if (!api) {
      return;
    }
    onSelect(api);
    api.on("reInit", onSelect);
    api.on("select", onSelect);
    return () => {
      api?.off("select", onSelect);
    };
  }, [api, onSelect]);
  return <CarouselContext.Provider value={{
    carouselRef,
    api: api,
    opts,
    orientation: orientation || (opts?.axis === "y" ? "vertical" : "horizontal"),
    scrollPrev,
    scrollNext,
    canScrollPrev,
    canScrollNext
  }}>
        <div ref={ref} onKeyDownCapture={handleKeyDown} className={cn("relative", className)} role="region" aria-roledescription="carousel" {...props}>
          {children}
        </div>
      </CarouselContext.Provider>;
}, "72w3/pym1wz2ZcTGqySg56b8KiQ=", false, function () {
  return [useEmblaCarousel];
})), "72w3/pym1wz2ZcTGqySg56b8KiQ=", false, function () {
  return [useEmblaCarousel];
});
_c2 = Carousel;
Carousel.displayName = "Carousel";
const CarouselContent = _s3(React.forwardRef(_c3 = _s3(({
  className,
  ...props
}, ref) => {
  _s3();
  const {
    carouselRef,
    orientation
  } = useCarousel();
  return <div ref={carouselRef} className="overflow-hidden">
      <div ref={ref} className={cn("flex", orientation === "horizontal" ? "-ml-4" : "-mt-4 flex-col", className)} {...props} />
    </div>;
}, "YNqN7/p8l2NfYueiPechI4IqsYo=", false, function () {
  return [useCarousel];
})), "YNqN7/p8l2NfYueiPechI4IqsYo=", false, function () {
  return [useCarousel];
});
_c4 = CarouselContent;
CarouselContent.displayName = "CarouselContent";
const CarouselItem = _s4(React.forwardRef(_c5 = _s4(({
  className,
  ...props
}, ref) => {
  _s4();
  const {
    orientation
  } = useCarousel();
  return <div ref={ref} role="group" aria-roledescription="slide" className={cn("min-w-0 shrink-0 grow-0 basis-full", orientation === "horizontal" ? "pl-4" : "pt-4", className)} {...props} />;
}, "bPPpMbUdjWnfcwMzP4altEp5ZJs=", false, function () {
  return [useCarousel];
})), "bPPpMbUdjWnfcwMzP4altEp5ZJs=", false, function () {
  return [useCarousel];
});
_c6 = CarouselItem;
CarouselItem.displayName = "CarouselItem";
const CarouselPrevious = _s5(React.forwardRef(_c7 = _s5(({
  className,
  variant = "outline",
  size = "icon",
  ...props
}, ref) => {
  _s5();
  const {
    orientation,
    scrollPrev,
    canScrollPrev
  } = useCarousel();
  return <Button ref={ref} variant={variant} size={size} className={cn("absolute  h-8 w-8 rounded-full", orientation === "horizontal" ? "-left-12 top-1/2 -translate-y-1/2" : "-top-12 left-1/2 -translate-x-1/2 rotate-90", className)} disabled={!canScrollPrev} onClick={scrollPrev} {...props}>
      <ArrowLeft className="h-4 w-4" />
      <span className="sr-only">Previous slide</span>
    </Button>;
}, "StVzzFT/dKvE6v0nHx014nh7DNA=", false, function () {
  return [useCarousel];
})), "StVzzFT/dKvE6v0nHx014nh7DNA=", false, function () {
  return [useCarousel];
});
_c8 = CarouselPrevious;
CarouselPrevious.displayName = "CarouselPrevious";
const CarouselNext = _s6(React.forwardRef(_c9 = _s6(({
  className,
  variant = "outline",
  size = "icon",
  ...props
}, ref) => {
  _s6();
  const {
    orientation,
    scrollNext,
    canScrollNext
  } = useCarousel();
  return <Button ref={ref} variant={variant} size={size} className={cn("absolute h-8 w-8 rounded-full", orientation === "horizontal" ? "-right-12 top-1/2 -translate-y-1/2" : "-bottom-12 left-1/2 -translate-x-1/2 rotate-90", className)} disabled={!canScrollNext} onClick={scrollNext} {...props}>
      <ArrowRight className="h-4 w-4" />
      <span className="sr-only">Next slide</span>
    </Button>;
}, "VthXVrvg+0LPsG5FRGeAaBGswm4=", false, function () {
  return [useCarousel];
})), "VthXVrvg+0LPsG5FRGeAaBGswm4=", false, function () {
  return [useCarousel];
});
_c10 = CarouselNext;
CarouselNext.displayName = "CarouselNext";
export { Carousel, CarouselContent, CarouselItem, CarouselPrevious, CarouselNext };
var _c, _c2, _c3, _c4, _c5, _c6, _c7, _c8, _c9, _c10;
$RefreshReg$(_c, "Carousel$React.forwardRef");
$RefreshReg$(_c2, "Carousel");
$RefreshReg$(_c3, "CarouselContent$React.forwardRef");
$RefreshReg$(_c4, "CarouselContent");
$RefreshReg$(_c5, "CarouselItem$React.forwardRef");
$RefreshReg$(_c6, "CarouselItem");
$RefreshReg$(_c7, "CarouselPrevious$React.forwardRef");
$RefreshReg$(_c8, "CarouselPrevious");
$RefreshReg$(_c9, "CarouselNext$React.forwardRef");
$RefreshReg$(_c10, "CarouselNext");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;